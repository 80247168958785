import React, { useEffect, useState } from 'react'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from 'gatsby';
import Carousel from 'react-multi-carousel';

const RelatedArticlesCarousel = ({ tags, title, language }) => {
  const data = useStaticQuery(graphql`
  query RelatedBlogArticles {
    articles: allMdx {
      posts: nodes {
        id
        fields {
            slug
        }
        frontmatter {
          language
          type
          title
          excerpt
          categories
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 800
                height: 360
                quality: 95
                tracedSVGOptions: {color: "#00ab6b"}
                placeholder: BLURRED
                transformOptions: {cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
          coverAlt
        }
      }
    }
  }
`);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1
    }
  };

  const articlesAreRelated = (article) => {
    if (article.frontmatter.language !== language) {
      return false;
    }

    if (tags) {
      for (let tag1 of tags) {
        for (let tag2 of article.frontmatter.categories) {
          if (tag1 === tag2) {
            return true;
          }
        }
      }
    }

    return false;
  }

  const relatedArticles = data?.articles?.posts?.filter(x => x.frontmatter.type === 'blog' && x.frontmatter.title !== title && articlesAreRelated(x));

  return relatedArticles && relatedArticles.length ? (
    <div className={"flex flex-col w-full px-5 md:px-10 lg:px-20 xl:px-40 bg-gray-100 feature-details-carousel"}>
      <h2 className={"font-light uppercase my-10 lg:text-lg"}>POWIĄZANE<span className={"font-semibold"}>ARTYKUŁY</span>
      </h2>
      <Carousel responsive={responsive} className={"mb-10"} showDots>
        {relatedArticles.map((article, index) => (
          <Link to={article.fields.slug.replace("/posts", "").replace("/en/", "/")} className={"w-full"} key={index}>
            <div className={"mx-5 my-5"}>
              <div
                className={"flex flex-col w-full hover:bg-primary-default text-gray-600 hover:text-white items-center feature-card rounded-xl bg-white shadow"}>
                {article?.frontmatter?.cover?.childImageSharp?.gatsbyImageData && <GatsbyImage
                  image={article?.frontmatter?.cover?.childImageSharp?.gatsbyImageData}
                  alt={article.frontmatter.coverAlt}
                  className={"w-full bg-white card-image rounded-t-xl"} />}
                <div className={"p-2 py-5 w-full flex flex-col bg-gray-200 details-blog-card-text rounded-b-xl"}>
                  <h3 className={"text-sm uppercase font-semibold"}>{article.frontmatter.title}</h3>
                  <p className={"text-sm xl:text-md pb-2"}>{article?.frontmatter?.excerpt} <span
                    className={"text-primary-blockquote feature-more"}>(...więcej)</span></p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </Carousel>
    </div>) : null;
};

export default RelatedArticlesCarousel;
