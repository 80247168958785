import React, { useState } from 'react'
import { graphql } from "gatsby"
import BlogDetails from './blog-details'

const BlogPost = (props) => {
  return  (<BlogDetails data={props.data} pageContext={props.pageContext} />);
};

export default BlogPost;

export const query = graphql`query BlogQuery($slug: String!) {
  mdx(fields: {slug: {eq: $slug}}) {
    body
    fields {
      slug
      lang
      canonical
      relatedPages {
        en {
            url
            country
        }
        it {
            url
            country
        }
        ro {
            url
            country
        }
        es {
            url
            country
        }
        fr {
            url
            country
        }
        pl {
            url
            country
        }
        de {
            url
            country
        }
        cz {
            url
            country
        }
      }
    }
    frontmatter {
      metaTitle
      metaDescription
      metaImage {
        publicURL
      }
      metaCover {
        publicURL
      }
      metaKeywords
      twitterCard
      ldJson
      title
      path
      categories
      cover {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            quality: 95
            tracedSVGOptions: {color: "#00ab6b"}
            placeholder: BLURRED
          )
        }
      }
      coverAlt
    }
  }
}
`
